import type { MutableRefObject } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { DropDown } from './components/DropDown';
import { Label } from './components/Label';
import { RangeContent } from './components/RangeContent';

import { useDropdown } from './hooks/useDropdown';
import { useGenerateOptions } from './hooks/useGenerateOptions';
import { useLabel } from './hooks/useLabel';
import { useRangeInput } from './hooks/useRangeInput';

import type { RangeInputPropTypes } from './types';

import { Styles } from './styles';

import css from './index.module.scss';

// i18n-extract-keys ["range_label", "range_min_only", "range_max_only", "range_min_max"]
// i18n-extract-keys ["range_value", "range_label_from", "range_label_to", "act_select_option"]
const defaultTranslationKeys = {
  label: 'range_label',
  fromValue: {
    label: 'range_min_only',
  },
  toValue: {
    label: 'range_max_only',
  },
  fromToValue: 'range_min_max',
  value: 'range_value',
  from: 'range_label_from',
  to: 'range_label_to',
  title: 'act_select_option',
};

export const RangeInput = forwardRef(
  (
    {
      fieldLabel,
      customClass,
      customControlClass,
      min,
      max,
      minvalue,
      maxvalue,
      step,
      translationKeys, // TODO: Refactor this with a list of callbacks for obtaining labels
      placement,
      onChange,
      footer,
      keepZeros = false,
      shortLabelValues = false,
      icon,
    }: RangeInputPropTypes,
    element: MutableRefObject<HTMLDivElement>
  ) => {
    const { trans } = useTranslations();
    const translations = translationKeys || defaultTranslationKeys;

    // refs
    const elementDefault = useRef<HTMLDivElement>();
    const labelRef = useRef<HTMLDivElement>();
    const dropdown = useRef<HTMLDivElement>();

    element = element || elementDefault;

    const { fullscreenMode, isOpen, close } = useDropdown(
      element,
      dropdown,
      labelRef,
      placement
    );

    const { rangeState, setValue, commit, setCurrentRange } = useRangeInput(
      minvalue,
      maxvalue,
      onChange,
      keepZeros
    );

    const label = useLabel(
      rangeState,
      translations,
      keepZeros,
      shortLabelValues
    );

    const options = useGenerateOptions(min, max, step, translations.value);

    // TODO Move in an handler inside use dropdown in order
    // to avoid state updates inside useEffect
    useEffect(() => {
      !isOpen && commit();
      if (isOpen) {
        // Reset the current range every time I reopen the dropdown
        setCurrentRange('min');
      }
    }, [isOpen]);

    return (
      <div
        className={clsx(
          css[Styles.rangeInput],
          isOpen && css[Styles.isOpen],
          isOpen && css[Styles.hasFocused],
          customClass
        )}
        ref={element}
      >
        <Label
          labelRef={labelRef}
          label={label}
          customControlClass={customControlClass}
          fieldLabel={fieldLabel}
          icon={icon}
        />
        <DropDown
          // i18n-extract-disable-next-line
          title={trans(translations.title)}
          isOpen={isOpen}
          isMobile={fullscreenMode}
          dropdownRef={dropdown}
          handleClose={close}
        >
          <RangeContent
            rangeState={rangeState}
            setValue={setValue}
            commit={commit}
            setCurrentRange={setCurrentRange}
            close={close}
            isMobile={fullscreenMode}
            options={options}
            translationKeys={translations}
            keepZeros={keepZeros}
          />
          {footer && <div className={css[Styles.footer]}>{footer}</div>}
        </DropDown>
      </div>
    );
  }
);

RangeInput.displayName = 'RangeInput';
