import { useEffect, useRef } from 'react';
import { Align, ensureVisible } from '@pepita/dom-ensure-visible';
import { clsx } from 'clsx';

import { getCurrentRangeValue } from '../hooks/useRangeInput';

import type { ListProps } from '../types';

import { Styles } from '../styles';

import css from '../index.module.scss';

export function RangeList({ rangeState, items, onClick }: ListProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const selectedElement = useRef<HTMLLIElement>(null);

  // Centering the list with the selected element
  useEffect(() => {
    if (selectedElement?.current) {
      ensureVisible(selectedElement.current, listRef.current, {
        force: true,
        align: Align.Center,
      });
    }
  }, [rangeState, listRef]);

  const currentValue = getCurrentRangeValue(rangeState);

  return (
    <div
      ref={listRef}
      className={clsx(
        css[Styles.listContainer],
        rangeState.currentRange === 'max' && css[Styles.listRight]
      )}
    >
      <ul className={css[Styles.list]}>
        {items.map((item, index) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            ref={item.value === currentValue ? selectedElement : null}
            key={index}
            className={clsx(
              css[Styles.stackItem],
              css[Styles.listItem],
              item.value === currentValue && css[Styles.listItemSelected]
            )}
            onClick={() => onClick(item)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.keyCode === 13) {
                onClick(item);
              }
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
