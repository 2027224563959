import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

export type Placement = 'top' | 'bottom' | 'auto';

export const usePlacement = (
  elementPlacement: Placement,
  dropdown: MutableRefObject<HTMLElement>,
  isOpen: boolean
) => {
  /** Auto placement */
  const [placement, setPlacement] = useState<Placement>(elementPlacement);

  useEffect(() => {
    if (!isOpen) return;
    if (placement !== 'auto') return;

    if (!dropdown.current) return;

    const rect = dropdown.current.getBoundingClientRect();

    setPlacement(rect.bottom > window.innerHeight ? 'top' : 'bottom');
  }, [isOpen, dropdown, placement]);

  /**
   * Resets placement to recalculate it on next opening
   */
  useEffect(() => {
    if (elementPlacement !== 'auto') return;
    if (isOpen) return;

    setPlacement('auto');
  }, [isOpen, elementPlacement]);

  return placement;
};
