import { useEffect } from 'react';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/GeographySearch';

export const GEOGRAPHY_SEARCH_STORAGE_KEY = 'geography_search';

export const usePersistGeographySearch = () => {
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);

  useEffect(() => {
    localStorage.setItem(
      GEOGRAPHY_SEARCH_STORAGE_KEY,
      JSON.stringify(geographyData)
    );
  }, [geographyData]);
};
