import type { ReactElement, Ref } from 'react';
import { join } from '@pepita/querystring';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { ErrorBoundary } from 'src/components/ErrorBoundary';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { useIsAlternate } from 'src/hooks/useIsAlternate';

import type { PepitaStripeHandle } from 'src/libs/ui/pepita-stripe';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import type { AgencyEssential } from 'src/types/agency';

import type { AgencyCardProps } from '../AgencyCard';
import { AgencyCard, AgencyCardWithCover } from '../AgencyCard';

import { PromoBox } from './PromoBox';
import { TimeContract } from './TimeContract';

import css from './index.module.scss';

export type AgencyCarouselProps = {
  title?: ReactElement | string;
  titleClass?: string;
  items: {
    agency: AgencyEssential;
    customClass?: string;
  }[];
  withPromoBox?: boolean;
  from?: string;
  stripeRef?: Ref<PepitaStripeHandle>;
  hasCover?: boolean;
  customClass?: string;
  innerArrow?: boolean;
};

export function AgencyCarousel({
  title,
  titleClass,
  customClass,
  items,
  hasCover,
  withPromoBox,
  from,
  stripeRef,
  innerArrow,
}: AgencyCarouselProps) {
  const { trans } = useTranslations();
  const isAlternate = useIsAlternate();

  const sellerLeadUrl = getProductConfig('sellerLeadUrl');
  const evaluateBuildingUrl = getProductConfig('evaluateBuildingUrl');

  const hasSellerLead =
    isFeatureEnabled('SELLER_LEAD') && !isAlternate && sellerLeadUrl;
  const hasAppraisalHPUrl =
    !isFeatureEnabled('SELLER_LEAD') && evaluateBuildingUrl;

  if (!items || items.length === 0) return null;

  let carouselItems: AgencyCardProps[] = [];

  carouselItems = items;

  // filter the results based on the number of paying agencies

  if (isFeatureEnabled('AGENCIES_EXPERT_SCARSITY_ENABLED')) {
    const isPaidItems = items.filter((item) => item.agency.paid);
    const isFreeItems = items.filter((item) => !item.agency.paid);

    if (isPaidItems.length === 0) {
      // show 2 non-paying agencies
      carouselItems = isFreeItems.slice(0, 2);
    } else if (isPaidItems.length === 1) {
      // show the paying agency + 1 non-paying agency
      carouselItems = [...isPaidItems, ...isFreeItems.slice(0, 1)];
    } else {
      // show only the paying agencies
      carouselItems = isPaidItems;
    }
  }

  const carouselCards = carouselItems.map((item, i) => {
    //remove address to item.agency
    const { address, ...agency } = item.agency;
    const { timeContract } = agency;

    return (
      <PepitaStripe.Slide customClass={css[AgencyCarouselStyles.item]} key={i}>
        {hasCover ? (
          <AgencyCardWithCover
            ratio="wide"
            customClass={clsx(
              css[AgencyCarouselStyles.card],
              `${css[AgencyCarouselStyles.card]}--big`,
              item.customClass
            )}
            agency={agency}
            hasCover
          >
            {Boolean(timeContract) && (
              <TimeContract timeContract={timeContract} />
            )}
          </AgencyCardWithCover>
        ) : (
          <AgencyCard
            customClass={clsx(css[AgencyCarouselStyles.card], item.customClass)}
            agency={agency}
          >
            {Boolean(timeContract) && (
              <TimeContract timeContract={timeContract} />
            )}
          </AgencyCard>
        )}
      </PepitaStripe.Slide>
    );
  });

  if (hasSellerLead && withPromoBox) {
    carouselCards.push(
      <PepitaStripe.Slide
        key={'sell_house'}
        customClass={css[AgencyCarouselStyles.item]}
      >
        <PromoBox
          url={`${sellerLeadUrl}${from ? `?from=${from}` : ''}`}
          title={trans('lbl_sell_house_fast')}
          text={trans('area_agencies_help_sell_house_text')}
          action={trans('act_sell_with_agency')}
          hasCover={hasCover}
        />
      </PepitaStripe.Slide>
    );
  }

  if (hasAppraisalHPUrl && withPromoBox) {
    carouselCards.push(
      <PepitaStripe.Slide
        key={'evaluate_house'}
        customClass={css[AgencyCarouselStyles.item]}
      >
        <PromoBox
          url={`${evaluateBuildingUrl}index.php?agent=1`}
          title={trans('lbl_evaluate_house')}
          text={trans('area_agencies_help_evaluate_house_text')}
          action={trans('act_evaluate_with_agency')}
          hasCover={hasCover}
        />
      </PepitaStripe.Slide>
    );
  }

  return (
    <ErrorBoundary>
      {title && (
        <div className={clsx(css[AgencyCarouselStyles.title], titleClass)}>
          {title}
        </div>
      )}
      <PepitaStripe
        customClass={clsx(
          css[AgencyCarouselStyles.carousel],
          'cy-agency-carousel',
          customClass && customClass
        )}
        spaced
        ref={stripeRef}
        innerArrow={innerArrow}
      >
        {carouselCards}
      </PepitaStripe>
    </ErrorBoundary>
  );
}

export enum AgencyCarouselStyles {
  carousel = 'in-agencyCarousel',
  title = 'in-agencyCarousel__title',
  isSmall = 'in-agencyCarousel--singleItem',
  item = 'in-agencyCarousel__item',
  card = 'in-agencyCarousel__card',
}

export const formatAgencies = (
  agencies: AgencyEssential[],
  from: string
): AgencyCarouselProps['items'] =>
  agencies.map((agency) => {
    return {
      agency: {
        ...agency,
        agencyUrl: from
          ? join(agency.agencyUrl || '', `from=${from}`)
          : agency.agencyUrl,
      },
      customClass: agency.visibility
        ? css['in-agencyCarousel__vetrina']
        : undefined,
    };
  }) as AgencyCarouselProps['items'];
