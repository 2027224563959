import type { KeyboardEvent, MutableRefObject } from 'react';
import { forwardRef, useEffect } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { useSetFormattedValue } from '../hooks/useSetFormattedValue';

import type { InputProps } from '../types';

import { Styles } from '../styles';

import css from '../index.module.scss';

export const Input = forwardRef(
  (
    {
      placeholder,
      value,
      onFocus,
      onInput,
      onEnterPress,
      keepZeros,
    }: InputProps,
    inputRef: MutableRefObject<HTMLInputElement>
  ) => {
    const { trans } = useTranslations();

    useSetFormattedValue(inputRef, value, keepZeros);

    /**
     * Qua aspettiamo che il valore dell'input sia aggiornato
     * per mostrare il nuovo placeholder.
     *
     * In questo modo quando facciamo il passaggio da "Indifferente" a "Da/A"
     * L'utente vedrà "Indifferente -> valore (eg. 1000)" anzichè "Indifferente -> Da -> valore"
     * nascondendo di fatti il cambio di placeholder
     */
    useEffect(() => {
      // i18n-extract-disable-next-line
      inputRef.current.placeholder = trans(placeholder);
    }, [placeholder]);

    function handleKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
      if (evt.key === 'Enter' || evt.keyCode === 13) {
        onEnterPress(evt);
      }
    }

    // i18n-extract-disable
    return (
      <input
        ref={inputRef}
        className={css[Styles.input]}
        type="tel"
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onInput={onInput}
      />
    );
    // i18n-extract-enable
  }
);

Input.displayName = 'Input';
