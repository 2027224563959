import type { Dispatch } from 'react';
import { useCallback, useEffect, useReducer } from 'react';

import type { RangeInputState } from '../types';

type Actions =
  | {
      type: 'SET_VALUE';
      payload: number;
    }
  | {
      type: 'SET_CURRENT_RANGE';
      payload: 'max' | 'min';
    }
  | { type: 'SET_STATE'; payload: RangeInputState }
  | { type: 'RESET' };

export const getMinMax = (state) => ({ min: state.min, max: state.max });
export const getCurrentRangeValue = (state) =>
  getMinMax(state)[state.currentRange];
export const safeGetValue = (value, keepZeros) =>
  value || (keepZeros && value === 0) ? value : null;

let oldState = null;

export const useRangeInput = (
  defaultMin: number,
  defaultMax: number,
  onChange: (state: RangeInputState) => void,
  keepZeros: boolean
) => {
  const [state, dispatch]: [RangeInputState, Dispatch<Actions>] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_STATE': {
          return {
            ...state,
            ...action.payload,
          };
        }

        case 'SET_VALUE': {
          return {
            ...state,
            [state.currentRange]: action.payload,
          };
        }

        case 'SET_CURRENT_RANGE': {
          return {
            ...state,
            currentRange: action.payload,
          };
        }
      }
    },
    {
      min: defaultMin,
      max: defaultMax,
      currentRange: 'min',
    }
  );

  useEffect(() => {
    if (oldState && oldState.currentRange !== state?.currentRange) {
      commit();
    }

    oldState = state;
  }, [state]);

  function setValue(value: number) {
    dispatch({
      type: 'SET_VALUE',
      payload: value,
    });
  }

  useEffect(() => {
    // Reset the component when values changed from the outside
    setState({ min: defaultMin, max: defaultMax });
  }, [defaultMin, defaultMax]);

  const commit = useCallback(() => {
    let { max, min } = state;

    max = safeGetValue(max, keepZeros);
    min = safeGetValue(min, keepZeros);

    if (max !== null && min !== null && max < min) {
      // Swapping min and max values only when they
      // are both defined
      max = state.min;
      min = state.max;
    }

    const nextState: RangeInputState = {
      min: min || min === 0 ? min : null,
      max: max || max === 0 ? max : null,
      currentRange: 'min',
    };

    onChange(nextState);
  }, [state]);

  function setCurrentRange(range: 'min' | 'max') {
    if (state.currentRange === range) return;
    dispatch({
      type: 'SET_CURRENT_RANGE',
      payload: range,
    });
  }

  function setState(value) {
    dispatch({
      type: 'SET_STATE',
      payload: value,
    });
  }

  return {
    rangeState: state,
    setValue,
    setState,
    commit,
    setCurrentRange,
  };
};
