import type { MutableRefObject } from 'react';
import { useCallback, useState } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useListener } from 'src/hooks/useListener';
import type { Placement } from './usePlacement';
import { usePlacement } from './usePlacement';

export const useDropdown = (
  element: MutableRefObject<HTMLElement>,
  dropdown: MutableRefObject<HTMLElement>,
  label: MutableRefObject<HTMLElement>,
  elementPlacement: Placement
) => {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((event?: Event) => {
    if (event) {
      event.stopPropagation();
    }

    setIsOpen(true);
  }, []);

  const close = useCallback((event?: Event) => {
    if (event) {
      event.stopPropagation();
    }

    setIsOpen(false);
  }, []);

  /** Manage Placement **/
  const placement = usePlacement(elementPlacement, dropdown, isOpen);

  /**
   * This handles clicks on the document (close) and on the label (open)
   */
  useListener(element, 'click', open, !isOpen);
  useListener(label, 'click', close, isOpen);
  useClickOutside(element, close, isOpen && !maxScreenWidthSm);

  return {
    fullscreenMode: maxScreenWidthSm,
    isOpen,
    open,
    close,
    placement,
  };
};
