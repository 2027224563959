import type { ReactNode } from 'react';
import clsx from 'clsx';

import type { AGENCY_TYPE } from 'src/constants/real-estate';

import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';

import { AgencyCardLogo } from '../AgencyCardLogo';

import css from './styles.module.scss';

interface AgencyCardContentProps {
  agencyImageUrls?: string;
  type: AGENCY_TYPE;
  displayName?: string;
  address?: string;
  textAgencyUrl?: string;
  children?: ReactNode;
}

export function AgencyCardContent({
  agencyImageUrls,
  type,
  displayName,
  address,
  textAgencyUrl,
  children,
}: AgencyCardContentProps) {
  return (
    <>
      <AgencyCardLogo
        imageUrls={agencyImageUrls}
        agencyType={type}
        imageAlt={displayName || ''}
      />
      <PepitaMediaObjectContent>
        <PepitaMediaObjectTop>
          <PepitaMediaObjectTitle
            customClass={css['in-agencyCardContent__title']}
          >
            {displayName}
          </PepitaMediaObjectTitle>
          {address && (
            <p className={css['in-agencyCardContent__info']}>{address}</p>
          )}
          {textAgencyUrl && (
            <p
              className={clsx(
                css['in-agencyCardContent__info'],
                css['in-agencyCardContent__info--action']
              )}
            >
              {textAgencyUrl}
            </p>
          )}
        </PepitaMediaObjectTop>
        {Boolean(children) && (
          <PepitaMediaObjectBottom
            customClass={css['in-agencyCardContent__bottom']}
          >
            {children}
          </PepitaMediaObjectBottom>
        )}
      </PepitaMediaObjectContent>
    </>
  );
}
