import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

interface DossierSkeletonProps {
  itemCustomClass?: string;
}

export function DossierSkeleton({ itemCustomClass }: DossierSkeletonProps) {
  return (
    <div className={itemCustomClass}>
      <PepitaSkeleton type="image" imageRatio="wide" />
      <PepitaSkeleton type="text" items={2} />
      <PepitaSkeleton type="text" items={5} />
    </div>
  );
}
