import { useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { useFormatValue } from './useFormatValue';

import type { ListOptionProps } from '../types';

const generateOptions = (min, max, steps, labelKey, parseValue, trans) => {
  if (typeof steps === 'number') {
    steps = { 0: steps };
  }

  const options = [
    {
      value: null,
      label: trans('lbl_any'),
    },
  ];

  const thresholds = Object.keys(steps)
    .map(Number)
    .sort((a, b) => a - b);

  for (let i = min, t = thresholds.shift(); i <= max; i += steps[t]) {
    if (thresholds.length && thresholds[0] <= i) {
      t = thresholds.shift();
    }

    options.push({
      value: i,
      label: parseValue(i, labelKey),
    });
  }

  return options;
};

export const useGenerateOptions = (
  min: number,
  max: number,
  steps: number | object,
  labelKey: string
): ListOptionProps[] => {
  const { trans } = useTranslations();
  const { parseValue } = useFormatValue();

  return useMemo(
    () => generateOptions(min, max, steps, labelKey, parseValue, trans),
    [min, max, steps, labelKey]
  );
};
