import { useState } from 'react';

import type { RangeContentProps } from '../types';

import { InputContainer } from './InputContainer';
import { RangeList } from './RangeList';

export function RangeContent({
  rangeState,
  setValue,
  commit,
  setCurrentRange,
  close,
  isMobile,
  options,
  translationKeys,
  keepZeros,
}: RangeContentProps) {
  // In questo stato ci salviamo se l'utente ha cliccato su "Indifferente"
  const [anyMin, setAnyMin] = useState(false);
  const [anyMax, setAnyMax] = useState(false);

  const placeholder = {
    min: anyMin ? options[0].label : translationKeys.from,
    max: anyMax ? options[0].label : translationKeys.to,
  };

  function handleChangePlaceholder(value) {
    if (rangeState.currentRange === 'min') {
      setAnyMin(value === null);
    } else {
      setAnyMax(value === null);
    }
  }

  return (
    <>
      <InputContainer
        isMobile={isMobile}
        setCurrentRange={setCurrentRange}
        setValue={setValue}
        commit={commit}
        rangeState={rangeState}
        close={close}
        keepZeros={keepZeros}
        labelPlaceholder={placeholder}
        onChange={(evt) => {
          handleChangePlaceholder(evt.target.value);
        }}
      />
      <RangeList
        rangeState={rangeState}
        items={options}
        onClick={(item) => {
          setValue(item.value);
          if (rangeState.currentRange === 'min') {
            setCurrentRange('max');
          } else {
            !isMobile && close();
          }

          handleChangePlaceholder(item.value);
        }}
      />
    </>
  );
}
