import type { MutableRefObject } from 'react';

import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import { useFormatValue } from './useFormatValue';
import { safeGetValue } from './useRangeInput';

const setFormattedValue = (input: HTMLInputElement, value: string) => {
  if (value === input.value) {
    return;
  }

  let cursor = input.selectionStart;

  if (value.slice(0, cursor) !== input.value.slice(0, cursor)) {
    cursor += value.length - input.value.length;
  }

  input.value = value;
  if (input === document.activeElement) {
    input.setSelectionRange(cursor, cursor);
  }
};

export const useSetFormattedValue = (
  inputRef: MutableRefObject<HTMLInputElement>,
  value: number,
  keepZeros
) => {
  const { parseValue } = useFormatValue();

  useIsomorphicLayoutEffect(() => {
    value = safeGetValue(value, keepZeros);
    const formattedValue = value !== null ? parseValue(value) : '';

    if (!inputRef.current.value) {
      inputRef.current.value = formattedValue;
    } else {
      setFormattedValue(inputRef.current, formattedValue);
    }
  });
};
