import type { ReactNode } from 'react';
import { clsx } from 'clsx';

import {
  PepitaMediaObject,
  PepitaMediaObjectLink,
} from 'src/libs/ui/pepita-media-object';

import type { AgencyEssential } from 'src/types/agency';

import { absoluteURL } from 'src/utils/url';

import { AgencyCardContent } from '../AgencyCardContent/AgencyCardContent';

import css from './styles.module.scss';

export interface AgencyCardProps {
  agency: AgencyEssential;
  hasCover?: boolean;
  children?: ReactNode;
  customClass?: string;
}

export function AgencyCard({
  agency,
  hasCover = false,
  children,
  customClass,
}: AgencyCardProps) {
  const {
    agencyUrl,
    externalLinks,
    imageUrls,
    type,
    displayName,
    address,
    textAgencyUrl,
  } = agency;

  const agencyLink = agencyUrl || externalLinks?.url;
  const agencyHref = agencyLink ? absoluteURL(agencyLink) : null;
  const agencyImageUrls = imageUrls?.large || imageUrls?.small || undefined;

  if (agencyHref) {
    return (
      <PepitaMediaObjectLink
        href={agencyHref}
        target="_blank"
        rel="nofollow"
        direction="col"
        card
        padded
        customClass={clsx(
          css['in-agencyCard'],
          hasCover && css['has-cover'],
          customClass
        )}
      >
        <AgencyCardContent
          agencyImageUrls={agencyImageUrls}
          type={type}
          displayName={displayName}
          address={address}
          textAgencyUrl={textAgencyUrl}
        >
          {children}
        </AgencyCardContent>
      </PepitaMediaObjectLink>
    );
  }

  return (
    <PepitaMediaObject
      direction="col"
      card
      padded
      customClass={clsx(css['in-agencyCard'], hasCover && css['has-cover'])}
    >
      <AgencyCardContent
        agencyImageUrls={agencyImageUrls}
        type={type}
        displayName={displayName}
        address={address}
        textAgencyUrl={textAgencyUrl}
      >
        {children}
      </AgencyCardContent>
    </PepitaMediaObject>
  );
}
