import { useCallback } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { useDossierTracking } from './hooks/useDossierTracking';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import type { Article } from 'src/types/dossiers';

import css from './styles.module.scss';
export interface DossierProps {
  dossier: Article;
}
export function Dossier({ dossier }: DossierProps) {
  const { trans } = useTranslations();
  const { trackFeaturedArticleClick } = useDossierTracking();

  const handleClick = useCallback(() => {
    trackFeaturedArticleClick(dossier.id);
    window.open(dossier.slug, '_blank');
  }, [dossier, trackFeaturedArticleClick]);

  return (
    <>
      <div className={css['in-dossier']}>
        <PepitaFigure
          ratio="wide"
          bordered
          figureContent={dossier.photo.medium}
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
              handleClick();
            }
          }}
          role="button"
        >
          <PepitaBadge position="topLeft">
            {trans('lbl_dossiers_badge')}
          </PepitaBadge>
        </PepitaFigure>

        <a
          className={css['in-dossier__title']}
          href={dossier.slug}
          target="_blank"
        >
          {dossier.title}
        </a>
        <p>{dossier.description}</p>
      </div>
    </>
  );
}
