import { capitalize, useTranslations } from '@pepita-react/i18n';

import { getProductConfig } from 'src/config/product';

interface TimeContractProps {
  timeContract: number;
}

const domainName = getProductConfig('domainName');

export function TimeContract({ timeContract }: TimeContractProps) {
  const { trans } = useTranslations();

  return trans('lbl_on_site_since_year', {
    capitalize: true,
    params: [capitalize(domainName), timeContract],
    count: timeContract,
  });
}
