export const Styles = {
  rangeInput: 'nd-rangeInput',
  label: 'nd-rangeInput__control',
  inputContainer: 'nd-rangeInput__inputContainer',
  inputContainerMobile: 'nd-rangeInput__inputContainer--mobile',
  input: 'nd-rangeInput__input',
  listContainer: 'nd-rangeInput__list',
  arrow: 'nd-rangeInput__arrow',
  list: 'nd-stack',
  listRight: 'nd-rangeInput__list--right',
  stackItem: 'nd-stackItem',
  listItem: 'nd-rangeInput__listItem',
  listItemSelected: 'is-selected',
  menu: 'nd-rangeInput__menu',
  footer: 'nd-rangeInput__footer',
  isOpen: 'is-open',
  hasFocused: 'has-focused',
  isFocus: 'is-focus',
  iconControl: 'nd-rangeInput__iconControl',
  iconText: 'nd-rangeInput__iconText',
};
