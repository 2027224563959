import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';

import { useTrack } from 'src/tracking/hooks/useTrack';
import { EventName } from 'src/tracking/utils/enums';

export const useDossierTracking = () => {
  const track = useTrack();
  const { user } = useAuthContext();

  const trackFeaturedArticleClick = useCallback(
    (articleId: string) => {
      track(EventName.FEATURED_ARTICLE_CLICKED, {
        'Is User Logged': Boolean(user),
        'User ID': user?.uuid,
        'ID Article': articleId,
      });
    },
    [track, user]
  );

  return { trackFeaturedArticleClick };
};
