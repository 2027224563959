import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import {
  PepitaDialog,
  PepitaDialogContentFrameType,
  PepitaDialogFrameType,
} from 'src/libs/ui/pepita-dialog';

import type { DropDownProps } from '../types';

import { Styles } from '../styles';

import css from '../index.module.scss';

export function DropDown({
  title,
  isOpen,
  isMobile,
  children,
  handleClose,
  dropdownRef,
}: DropDownProps) {
  const { trans } = useTranslations();

  if (isOpen) {
    if (isMobile) {
      return (
        <PepitaDialog
          onClose={() => handleClose()}
          content={children}
          size={PepitaDialogFrameType.FULL}
          contentSize={PepitaDialogContentFrameType.FULL}
          contentNotScrollable
          title={title}
          footer={
            <PepitaButton variant="accent" onClick={() => handleClose()}>
              {trans('act_confirm')}
            </PepitaButton>
          }
        />
      );
    }

    return (
      <div
        className={clsx(css[Styles.menu], css[Styles.isOpen])}
        ref={dropdownRef}
      >
        {children}
      </div>
    );
  }

  return null;
}
