import type { AgencyEssential } from 'src/types/agency';

import type { AgencyCardProps } from '../AgencyCard';
import { AgencyCardSkeleton } from '../AgencyCardSkeleton';
import { AgencyCardWithCover } from '../AgencyCardWithCover';

interface AgencyCardAppraisalProps extends Omit<AgencyCardProps, 'agency'> {
  agency: AgencyEssential | undefined;
  isFetching?: boolean;
  isCompact: boolean;
}

export function AgencyCardAppraisal({
  agency,
  isFetching,
  customClass,
  children,
  isCompact,
}: AgencyCardAppraisalProps) {
  if (isFetching) {
    return <AgencyCardSkeleton isCompact={isCompact} />;
  }

  if (!agency) {
    return null;
  }

  return (
    <AgencyCardWithCover agency={agency} customClass={customClass}>
      {children}
    </AgencyCardWithCover>
  );
}
