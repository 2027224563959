import { memo } from 'react';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { LabelProps } from '../types';

import { Styles } from '../styles';

import css from '../index.module.scss';

export const Label = memo(function Label({
  label,
  labelRef,
  fieldLabel,
  icon,
}: LabelProps) {
  // TODO js-range-control is needed only when we want to close the range
  // component from the outside. We should find a more React-compliant
  // method to do this thing (context on a useDropdown hook?)
  return (
    <div
      className={clsx(css[Styles.label], 'js-range-control')}
      ref={labelRef}
      data-label={fieldLabel}
    >
      {icon ? (
        <span className={css[Styles.iconControl]}>
          <PepitaIcon {...icon} />
          <span className={css[Styles.iconText]}>{label}</span>
        </span>
      ) : (
        <span>{label}</span>
      )}
      <PepitaIcon name="arrow-right" customClass={css[Styles.arrow]} />
    </div>
  );
});
