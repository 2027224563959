import { useEffect, useRef } from 'react';
import { clsx } from 'clsx';

import { useFocusRef } from 'src/hooks/useFocusRef';
import { useFormatValue } from '../hooks/useFormatValue';
import { getMinMax } from '../hooks/useRangeInput';

import type { InputContainerProps } from '../types';

import { Styles } from '../styles';

import { Input } from './Input';

import css from '../index.module.scss';

export function InputContainer({
  isMobile,
  setCurrentRange,
  setValue,
  rangeState,
  close,
  keepZeros,
  labelPlaceholder,
  onChange,
}: InputContainerProps) {
  const { parseNumber } = useFormatValue();

  const leftInput = useFocusRef<HTMLInputElement>(null);
  const rightInput = useRef<HTMLInputElement>(null);

  // Sync the current range with the focused input on desktop
  useEffect(() => {
    if (
      rangeState.currentRange === 'min' &&
      leftInput.current &&
      leftInput.current !== document.activeElement
    ) {
      leftInput.current.focus();
    }

    if (
      rangeState.currentRange === 'max' &&
      rightInput.current &&
      rightInput.current !== document.activeElement
    ) {
      if (isMobile) {
        rightInput.current.classList.add(css[Styles.isFocus]);

        return () => {
          rightInput.current?.classList.remove(css[Styles.isFocus]);
        };
      }

      rightInput.current.focus();
    }
  }, [rangeState.currentRange, isMobile, leftInput, rightInput]);

  function onInput(evt) {
    setValue(parseNumber(evt.target.value));
    onChange(evt);
  }

  return (
    <div
      className={clsx(
        css[Styles.inputContainer],
        isMobile && css[Styles.inputContainerMobile]
      )}
    >
      <Input
        placeholder={labelPlaceholder.min}
        value={getMinMax(rangeState).min}
        onFocus={() => {
          setCurrentRange('min');
        }}
        onEnterPress={(evt) => {
          evt.preventDefault();
          setCurrentRange('max');
        }}
        onInput={onInput}
        ref={leftInput}
        keepZeros={keepZeros}
      />

      <Input
        placeholder={labelPlaceholder.max}
        value={getMinMax(rangeState).max}
        onInput={onInput}
        onFocus={() => {
          setCurrentRange('max');
        }}
        onEnterPress={(evt) => {
          evt.preventDefault();
          close();
        }}
        ref={rightInput}
        keepZeros={keepZeros}
      />
    </div>
  );
}
