import { clsx } from 'clsx';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL, alternateExternalUrl } from 'src/utils/url';

import SellHousePromoImage from './images/sell_house_promo_box.svg';

import css from './index.module.scss';

export enum AgencyCarouselStyles {
  card = 'in-agencyCarousel__card',
  promoTitle = 'in-agencyCarousel__promoTitle',
}

interface PromoBoxProps {
  url: string;
  title: string;
  text: string;
  action: string;
  hasCover?: boolean;
}

export function PromoBox({
  url,
  title,
  text,
  action,
  hasCover,
}: PromoBoxProps) {
  const { defaultLocale, locale } = useRouter();
  const isExternalBaseUrl = Boolean(getProductConfig('externalBaseUrl'));

  return (
    <a
      className={clsx(
        css[AgencyCarouselStyles.card],
        `${css[AgencyCarouselStyles.card]}--promo`,
        hasCover && `${css[AgencyCarouselStyles.card]}--big`
      )}
      href={
        isExternalBaseUrl
          ? alternateExternalUrl(
              `${url}`,
              defaultLocale as Language,
              locale as Language
            )
          : alternateAbsoluteURL(
              `${url}`,
              defaultLocale as Language,
              locale as Language
            )
      }
      target="_blank"
      rel="nofollow"
    >
      {hasCover && (
        <img src={SellHousePromoImage} alt="" width={211} height={109} />
      )}

      <span className={css[AgencyCarouselStyles.promoTitle]}>{title}</span>
      <p>{text}</p>
      <PepitaButton variant="link">{action}</PepitaButton>
    </a>
  );
}
