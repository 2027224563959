import { useTranslations } from '@pepita-react/i18n';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';

import { parseNumber } from 'src/utils/string';

const getShortValue = (value: number): (string | number)[] => {
  if (value >= 1000000) {
    return [value / 1000000, 'M'];
  }

  if (value >= 1000) {
    return [value / 1000, 'k'];
  }

  return [value, ''];
};

export const useFormatValue = () => {
  const { trans } = useTranslations();
  const formatNumber = useNumberFormatterCallback();

  return {
    parseValue: (
      value: string | number,
      labelKey: Nullable<string> = null
    ): string => {
      if (typeof value === 'string') {
        value = parseNumber(value, formatNumber);
      }

      const formattedValue = isNaN(value) ? '' : formatNumber(value);

      if (labelKey) {
        /* i18n-extract-disable-next-line */
        return trans(labelKey, {
          params: [formattedValue],
        });
      }

      return formattedValue;
    },
    parseShortValue: (
      value: string | number,
      labelKey: Nullable<string> = null
    ): string => {
      if (typeof value === 'string') {
        value = parseNumber(value, formatNumber);
      }

      const [shortValue, abbreviation] = getShortValue(value);

      const formattedValue = isNaN(value)
        ? ''
        : `${formatNumber(shortValue as number)}${abbreviation}`;

      if (labelKey) {
        /* i18n-extract-disable-next-line */
        return trans(labelKey, {
          params: [formattedValue],
        });
      }

      return formattedValue;
    },
    parseNumber: (value: number): number => {
      return parseNumber(value, formatNumber);
    },
  };
};
