import clsx from 'clsx';

import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import css from './styles.module.scss';

export function AgencyCardSkeleton({ isCompact }: { isCompact?: boolean }) {
  return (
    <div
      className={clsx(
        css['in-agencyCardSkeleton'],
        isCompact && css['is-compact']
      )}
    >
      <PepitaSkeleton
        type="image"
        imageRatio="wide"
        customClass={css['in-agencyCardSkeleton__image']}
      />
      <div className={clsx(css['in-agencyCardSkeleton__container'])}>
        <PepitaSkeleton
          type="image"
          imageRatio="custom"
          customClass={css['in-agencyCardSkeleton__logo']}
        />
        <PepitaSkeleton type="text" theme="light" items={3} />
      </div>
    </div>
  );
}
