import { useTranslations } from '@pepita-react/i18n';

import { useFormatValue } from './useFormatValue';
import { getMinMax, safeGetValue } from './useRangeInput';

import type { RangeInputState, translationKeysType } from '../types';

import type { Trans } from 'src/utils/i18nFormatters';

const getLabelText = (
  rangeState: RangeInputState,
  translationKeys: translationKeysType,
  trans: Trans,
  parseValue,
  keepZeros: boolean
) => {
  let { min: minValue, max: maxValue } = getMinMax(rangeState);

  minValue = safeGetValue(minValue, keepZeros);
  maxValue = safeGetValue(maxValue, keepZeros);

  const min = minValue !== null ? String(minValue) : null;
  const max = maxValue !== null ? String(maxValue) : null;

  /* i18n-extract-disable */
  switch (true) {
    case !min && !max:
      return trans(translationKeys.label);
    case min === max:
      if (translationKeys.sameValue) {
        return `${trans(translationKeys.sameValue.label, {
          params: [parseValue(min)],
          count: !translationKeys.sameValue.withCount ? 1 : parseValue(min),
        })}`;
      }

      return parseValue(min, translationKeys.value);
    case !max:
      return `${trans(translationKeys.fromValue.label, {
        params: [parseValue(min)],
        count: !translationKeys.fromValue.withCount ? 1 : parseValue(min),
      })}`;
    case !min:
      return `${trans(translationKeys.toValue.label, {
        params: [parseValue(max)],
        count: !translationKeys.toValue.withCount ? 1 : parseValue(max),
      })}`;
    default:
      return `${trans(translationKeys.fromToValue, {
        params: [parseValue(min), parseValue(max)],
      })}`;
  }
  /* i18n-extract-enable */
};

export const useLabel = (
  rangeState: any,
  translationKeys: translationKeysType,
  keepZeros: boolean,
  shortLabelValues: boolean
) => {
  const { trans } = useTranslations();
  const { parseValue, parseShortValue } = useFormatValue();

  return getLabelText(
    rangeState,
    translationKeys,
    trans,
    shortLabelValues ? parseShortValue : parseValue,
    keepZeros
  );
};
