import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';

import type { AGENCY_TYPE } from 'src/constants/real-estate';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import css from './styles.module.scss';

interface AgencyCardLogoProps {
  agencyType: AGENCY_TYPE;
  imageAlt: string;
  imageUrls?: string;
}

export function AgencyCardLogo({
  agencyType,
  imageAlt,
  imageUrls,
}: AgencyCardLogoProps) {
  return (
    <PepitaFigure
      figureContent={
        imageUrls ?? <AgencyPlaceholder agencyType={agencyType} bordered />
      }
      ratio="custom"
      alt={imageAlt}
      height={56}
      width={211}
      lazyLoad
      customClass={css['in-agencyCardLogo']}
      imageCustomClass={css['in-agencyCardLogo__image']}
    />
  );
}
